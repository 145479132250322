import axios from "axios";

const headers = {
  "Content-Type": "application/json"
};


const appleMusicHeaders = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkM3NzZTTTdKUFEifQ.eyJpYXQiOjE3MTY4NTAwNTgsImV4cCI6MTczMjQwMjA1OCwiaXNzIjoiRDYzUURROUg5VCJ9.c6QRsW5uLtChohPTZ4IyJh68BKmAF1e73cdL4AN5Wl7Yms67I25TfOh5afQ6LNn2U_6ntaiFwqZ9iqIi5asBRA'
}

const burl = "https://api.meka-app.com";
// const burl = "http://127.0.0.1:3054";
const amurl = `https://api.music.apple.com/v1/catalog`
const amuurl = `https://api.music.apple.com/v1/me`

const API = axios.create({ baseURL: burl });
API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
  }
})

const AMApi = {
  login: function(email, password) {
    return axios.post(
      `${burl}/login`,
      {
        email,
        password
      },
      {
        headers: headers
      }
    );
  },

  logUserInWithToken: function(userId, token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.post(
      `${burl}/loginWithToken`,
      {
        userId
      },
      {
        headers: headers
      }
    );
  },

  signup: function(send) {
    return axios.post(`${burl}/signup`, send, { headers: headers });
  },

  isAuth: function() {
    return localStorage.getItem("token") !== null;
  },

  logout: function() {
    localStorage.clear();
  },

  getPartitionInit: function(id) {
    if (id != null && id.length > 0) {
      return axios.get(`${burl}/partition/init/` + id);
    } else {
      return axios.get(`${burl}/partition/init`);
    }
  },

  getHome: function(style = 34) {
    return axios.get(`${burl}/v1/home/${style}`, { headers: headers });
  },

  getDiscover: function(style = 34) {
    return axios.get(`${burl}/v1/discover/${style}`, { headers: headers });
  },

  getSearch: function(term) {
    var body = {
      "s" : term
    }
    return axios.get(`${burl}/v1/search`, { headers: headers, params: body });
  },

  /*######################## Users ########################*/

  getUsersCount: function() {
    return axios.get(`${burl}/partition/users/count`); 
  },

  getUsers: function() {
    return axios.get(`${burl}/partition/users`); 
  },

  /*######################## Tracks ########################*/

  getTracksCount: function() {
    return axios.get(`${burl}/partition/tracks/count`); 
  },

  getTracks: function() {
    return axios.get(`${burl}/partition/tracks`); 
  },

  getPartitionArtistTracks: function(id) {
    return axios.get(`${burl}/partition/artists/${id}/tracks`); 
  },

  /*######################## Artist ########################*/
  getPartitionArtistsByStatus: function(status) {
    return axios.get(`${burl}/partition/artists/bystatus/${status}`);
  },

  getPartitionArtistByID: function(ID) {
    return axios.get(`${burl}/partition/artists/${ID}`);
  },

  getArtistsCount: function() {
    return axios.get(`${burl}/partition/artists/count`); 
  },

  syncAppleMusicAvatar: function(id) {
    return axios.get(`${burl}/partition/syncampicture/${id}`); 
  },

  sendMergeArtist: function(masterId, childrenId) {
    return axios.get(`${burl}/partition/artists/merge/${masterId}/${childrenId}`); 
  },

  sendDeleteArtist: function(id) {
    return axios.delete(`${burl}/partition/artists/${id}/delete`); 
  },

  sendValidateArtist: function(id) {
    return axios.post(`${burl}/partition/artists/${id}/validate`); 
  },

  sendCompleteArtist: function(id) {
    return axios.post(`${burl}/partition/artists/${id}/complete`); 
  },

  switchTrackArtist: function(masterId, childId, trackId, type) {
    return axios.post(
      `${burl}/partition/switchTrackArtist`,
      {
        masterId,
        childId,
        trackId,
        type
      },
      {
        headers: headers
      }
    );
  },

  updateArtist: function(artistID, data) {
    return axios.post(`${burl}/partition/artist/${artistID}/update`,
    data,
    {
      headers: headers
    }); 
  },

  getArtistTracks: function(id) {
    return axios.get(`${burl}/artists/${id}/tracks`); 
  },

  uploadArtistAvatar: function(id, file) {
    var formData = new FormData()
    formData.append('file', file, id)
    console.log(file.name)
    return axios.post(`${burl}/partition/artists/${id}/avatar`, formData, { headers : {'Content-Type': 'multipart/form-data'} }); 
  },

  /*######################## Ranks ########################*/
  getBestRankForTrackID: function(id, style = 34) {
    return axios.get(`${burl}/v1/ranks/best/${id}/${style}`); 
  },

  /*######################## Apple Music ########################*/
  getAppleMusicUser: function(token) {
    return axios.get(`${amuurl}/storefront`, { headers: {
      "Content-Type": "application/json",
      "Music-User-Token": `${token}`,
      'Authorization': 'Bearer eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkM3NzZTTTdKUFEifQ.eyJpYXQiOjE3MTY4NTAwNTgsImV4cCI6MTczMjQwMjA1OCwiaXNzIjoiRDYzUURROUg5VCJ9.c6QRsW5uLtChohPTZ4IyJh68BKmAF1e73cdL4AN5Wl7Yms67I25TfOh5afQ6LNn2U_6ntaiFwqZ9iqIi5asBRA'
    }});
  },

  getAppleMusicTrack: function(id) {
    return axios.get(`${amurl}/fr/songs/${id}`, 
    { headers: appleMusicHeaders });
  },
};

export default AMApi